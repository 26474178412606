<!-- @format -->

<template>
	<layout-default-new>
		<sub-navbar page="Accountant" />
		<div>
			<div>
				<section class="hero is-success margin-bottom-20">
					<div class="hero-body">
						<p class="title">View sale invoices</p>
						<p class="subtitle">Please select the invoice to preview</p>
					</div>
				</section>
			</div>
			<section
				style="
					font-weight: 900;
					background-color: rgb(76, 92, 122);
					padding: 20px;
					margin-bottom: -20px;
				"
			>
				<div v-if="showTimeInterval">
					<span style="color: white"
						>Between the dates: &nbsp; {{ beginningDate }} &nbsp; and &nbsp;
						{{ endingDate }}</span
					>
				</div>
				<div style="margin-top: 20px">
					<b-button :type="buttonType" @click="toggleDays" style="width: 250px">
						{{ buttonTag }}
					</b-button>
					<b-button
						class="is-danger"
						@click="refresh"
						style="margin-left: 20px; width: 250px"
					>
						Refresh
					</b-button>
				</div>
			</section>
			<div
				v-if="!!loading"
				style="
					font-weight: 800;
					color: red;
					margin-top: 30px;
					font-size: larger;
				"
			>
				Loading......................
			</div>
			<div class="margin-top-20" v-if="!loading">
				<ve-table
					:columns="columns"
					:rows="rows"
					:searchedOptions="true"
					:sort-options="sortOptions"
					:onRowDoubleClick="onRowDoubleClick"
					:onCellClick="onCellClick"
				>
					<template slot="table-row" slot-scope="props">
						<span v-if="props.column.field === 'select'">
							<b-button class="is-small is-success">Select</b-button>
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</ve-table>
			</div>
			<div v-if="!!isModalVisible">
				<modal-invoice-frame processType="accountant" />
			</div>
		</div>
	</layout-default-new>
</template>

<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import GetInvoicesQuery from '@/_srcv2/pages/sale-invoice/view-invoice/graphql/GetInvoicesQuery.graphql'
import { ref, reactive, onMounted, computed } from '@vue/composition-api'
import Store from '@/store'
import { useQuery } from '@vue/apollo-composable'
import ModalInvoiceFrame from '@/_srcv2/pages/_reports/get-data-and-report/invoice/ModalInvoiceFrame'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore'
import dateformat from 'dateformat'

export default {
	name: 'ViewSaleInvoices',
	components: {
		SubNavbar,
		ModalInvoiceFrame,
	},
	setup() {
		// ! -----------------------------------------------------------------
		const buttonType = ref('is-success')
		const today = ref(new Date())
		const days = ref(92)
		const buttonTag = ref('Get without time limited')
		const showTimeInterval = ref(true)
		const p1 = (condition) => {
			return new Promise((resolve, reject) => {
				if (condition) {
					console.log('Condition is ', condition)
					if (days.value === 92) {
						days.value = 1500
						showTimeInterval.value = false
					} else {
						days.value = 92
						showTimeInterval.value = false
					}
					if (buttonTag.value === 'Get without time limited') {
						buttonTag.value = 'Get last three months'
					} else {
						buttonTag.value = 'Get without time limited'
					}
					if (buttonType.value === 'is-success') {
						buttonType.value = 'is-info'
					} else {
						buttonType.value = 'is-success'
					}
					resolve(true)
				} else {
					const error = new Error('Condition is false')
					reject(error)
				}
			}).catch((err) => console.log('Error: ', err))
		}
		const toggleDays = () => {
			console.log('toggle is fired')
			p1(true).then(() => refresh())
		}
		const beginningDate = computed(() => {
			const bd = new Date().setTime(
				today.value - days.value * 24 * 60 * 60 * 1000,
			)
			console.log('bd', bd)
			console.log('myDate', dateformat(bd, 'yyyy-mm-dd'))
			return dateformat(bd, 'yyyy-mm-dd')
		})
		const endingDate = computed(() => {
			return dateformat(today.value, 'yyyy-mm-dd')
		})
		// ! -----------------------------------------------------------------

		// ---------------------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { loading, refetch } = useQuery(
			GetInvoicesQuery,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
				bd: beginningDate.value,
				ed: endingDate.value,
			}),
			options,
		)
		// ----------------------------------------------------------------------------
		const rows = ref([])
		const columns = ref([])
		const mapData = (array) => {
			return array.map((item) => {
				console.log('array, array')
				return {
					invoiceDate: item.invoice_date,
					invoiceNumber: item.invoice_number,
					invoiceType: item.invoice_type,
					customer: `${item.customer.customer_id} - ${item.customer.customer_title} - ${item.customer.customer_nickname}`,
					email: item.customer.customer_email,
				}
			})
		}
		const setData = (result) => {
			return new Promise((resolve, reject) => {
				if (result !== null || undefined) {
					const tableRows = mapData(result)
					resolve(tableRows)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const refresh = () => {
			refetch()
				.then((result) => setData(result.data.document_transactions))
				.then((tableRows) => (rows.value = tableRows))
				.then(() => console.log('rows.value', rows.value))
		}
		// ----------------------------------------------------------------------
		onMounted(() => {
			refresh()
		})
		// --------------------------------------------------------------------------
		columns.value = [
			{
				label: 'Select',
				field: 'select',
				width: '85px',
				sortable: false,
			},
			{
				label: 'Invoice Number',
				field: 'invoiceNumber',
				width: '110px',
			},
			{
				label: 'Invoice Date',
				field: 'invoiceDate',
				width: '110px',
			},
			{
				label: 'Invoice Type',
				field: 'invoiceType',
				width: '100px',
			},
			{
				label: 'Customer',
				field: 'customer',
				width: '540px',
			},
		]
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'invoiceNumber', type: 'desc' },
		}
		// -----------------------------------------------------------------------------------
		const getPdfReport = (row) => {
			setVariables(row)
				.then(() => (isModalVisible.value = true))
				.then(() =>
					console.log(
						'reportDocumentNumber: ',
						reportDocumentNumber.value,
						'row: ',
						row,
					),
				)
		}
		// ('select' || 'invoiceNumber')
		const onCellClick = (params) => {
			if (
				params.column.field === 'invoiceNumber' ||
				params.column.field === 'select'
			) {
				getPdfReport(params.row)
			}
		}
		const onRowDoubleClick = (params) => {
			getPdfReport(params.row)
		}
		//---------------------------------------------------------------------------------------------
		const {
			reportDocumentType,
			reportDocumentNumber,
			reportDocumentOurCompany,
			isSelectedDocumentLocked,
			isModalVisible,
			reportCustomer,
			reportCustomerEmail,
			reportDocumentDate,
			reportDocumentStatus,
			isSelectedDocumentReported,
		} = useProcessReportStore()
		// -------------------------------------------------------------------------------------------------
		const setVariables = (row) => {
			return new Promise((resolve, reject) => {
				if (row !== null || undefined) {
					console.log('*** setVariables row', row)
					reportDocumentType.value = 'sale-invoice'
					reportDocumentNumber.value = row.invoiceNumber
					reportDocumentOurCompany.value = Store.getters.getUserCurrentCompany
					isSelectedDocumentLocked.value = true
					isSelectedDocumentReported.value = true
					reportCustomer.value = row.customer
					reportCustomerEmail.value = row.email
					reportDocumentDate.value = row.invoiceDate
					reportDocumentStatus.value = 'locked'
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// ---------------------------------------------------------------------------------------
		return {
			loading,
			refresh,
			columns,
			rows,
			onCellClick,
			onRowDoubleClick,
			sortOptions,
			isModalVisible,
			toggleDays,
			beginningDate,
			endingDate,
			buttonTag,
			buttonType,
			showTimeInterval,
		}
	},
}
</script>

<style scoped></style>
